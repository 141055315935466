export enum ApiTags {
  KeyGroupCreate = 'KeyGroupCreate',
  KeyGroupUpdate = 'KeyGroupUpdate',
  KeyGroupEnable = 'KeyGroupEnable',
  KeyGroupDisable = 'KeyGroupDisable',
  KeyGroupDelete = 'KeyGroupDelete',
  KeyGroupImport = 'KeyGroupImport',

  PrestaCreate = 'PrestaCreate',
  PrestaEnable = 'PrestaEnable',
  PrestaDisable = 'PrestaDisable',

  AccountCreate = 'AccountCreate',
  AccountEnable = 'AccountEnable',
  AccountDisable = 'AccountDisable',

  ProductHasGroupCreate = 'ProductHasGroupCreate',
  ProductHasGroupDelete = 'ProductHasGroupDelete',
  ProductHasGroupQuantityUpdate = 'ProductHasGroupQuantityUpdate',
  ProductHasGroupEnable = 'ProductHasGroupEnable',
  ProductHasGroupDisable = 'ProductHasGroupDisable',

  TransactionRevoke = 'TransactionRevoke',
  TransactionResend = 'TransactionResend',

  AmazonConnect = 'AmazonConnect',
}

export const ApiTagsValues = Object.values(ApiTags);
