import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ReactModal from 'react-modal';

import { Root } from './views/root';
import { AppProviders } from 'shared/providers';
import { FullScreenLoading, Modal } from './shared/components';

import './i18n';
import { ChooseLanguage } from './shared/components/choose-language/choose-language';

const container = document.getElementById('root')!;
ReactModal.setAppElement(container);
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppProviders>
      <Suspense fallback={<FullScreenLoading />}>
        <ChooseLanguage />
        <Root />
        <Modal />
      </Suspense>
    </AppProviders>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
