import * as React from 'react';
import { SVGProps } from 'react';
const SvgTransactions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 77.27 57.54'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.transactions_svg__d{fill:#fff}'}</style>
    </defs>
    <g id='transactions_svg__b'>
      <g id='transactions_svg__c'>
        <path
          className='transactions_svg__d'
          d='M44.01 43.69c1.28.49 2.56.97 3.91 1.22 6.37 1.22 12.11-.14 17.13-4.28 3.71-3.06 6.03-7.01 6.89-11.74 1.08-5.99-.24-11.46-4.02-16.26-3.09-3.93-7.14-6.4-12.06-7.3-6.19-1.12-11.78.34-16.67 4.32-2.12 1.73-3.77 3.85-5.05 6.28-.16.31-.1.41.18.56.81.41 1.6.84 2.46 1.3-.77.47-1.48.91-2.19 1.35-.71.44-1.42.89-2.12 1.34-.04 0-.1 0-.08.08-.15-.02-.24.09-.35.16-1.64 1.01-3.28 2.01-4.93 3.02-.09.05-.18.1-.33.19l-.5-11.71c1.04.55 2.01 1.05 2.97 1.58.23.13.32.13.46-.13C33.6 6.46 39.53 2.01 47.57.41 48.87.15 50.2.03 51.53 0c.38.01.77 0 1.16 0 3.46.07 6.79.78 9.9 2.28C70.07 5.87 74.8 11.71 76.7 19.8c.37 1.57.54 3.17.57 4.78 0 .39-.01.77 0 1.16-.08 3.58-.85 7.01-2.43 10.22-3.41 6.93-8.85 11.45-16.3 13.54-1.86.52-3.77.78-5.71.81h-1.47c-2.99-.06-5.86-.69-8.65-1.77-.35-.14-.37-.28-.26-.6.53-1.41 1.03-2.84 1.54-4.26Z'
        />
        <path
          className='transactions_svg__d'
          d='M32.39 20.56c-.02-.07.04-.07.08-.08-.01.04-.02.08-.08.08ZM31.98 57.54h-1.92s-.04-.12-.07-.13c-1.29-.47-2.52-1.07-3.77-1.62-2.31-1.01-4.62-2.03-6.93-3.04-1.69-.74-3.38-1.49-5.07-2.24-2.07-.91-4.14-1.81-6.2-2.72-1.71-.75-3.42-1.52-5.15-2.25-1.31-.56-2.33-1.37-2.72-2.81-.02-.09-.06-.14-.16-.14v-1.92c.07 0 .12-.03.15-.11.89-2.29 1.96-4.5 2.92-6.76.47-1.1 1.01-2.17 1.41-3.29.11.04.22.08.33.13 10.04 4.45 20.08 8.9 30.12 13.36 1.29.57 2.57 1.15 3.85 1.73 0 .12-.02.23-.07.35-.42.95-.84 1.89-1.26 2.84-.85 1.93-1.71 3.86-2.54 5.8-.55 1.3-1.37 2.29-2.79 2.67-.09.02-.14.06-.15.16Zm2.12-7.18c-.02-.15-.16-.16-.26-.2-1.99-.89-3.98-1.77-5.97-2.66-.22-.1-.31-.07-.41.16-.45 1.05-.92 2.09-1.39 3.13-.1.22-.07.31.16.41 2.01.88 4.01 1.77 6.01 2.66.2.09.29.07.38-.14.45-1.05.92-2.09 1.38-3.13.03-.07.06-.15.09-.23ZM11.11 22.99c0 .07.03.13.1.15 1.56.46 2.99 1.23 4.48 1.87 2.82 1.21 5.62 2.46 8.43 3.69l6.93 3.06c2.59 1.14 5.18 2.28 7.77 3.41 1.53.67 2.42 1.78 2.61 3.47.12 1.03-.29 1.89-.67 2.77-.09.21-.16.42-.24.63-.06-.02-.13-.04-.19-.07-9.25-4.1-18.49-8.19-27.73-12.29-2.18-.97-4.36-1.95-6.55-2.92.37-.35.45-.85.66-1.29.6-1.23 1.53-2.04 2.89-2.32.11-.02.22-.01.23-.16h.4c.03.07.05.06.08 0h.8Z'
        />
        <path className='transactions_svg__d' d='M10.31 22.99c-.03.06-.05.07-.08 0h.08Z' />
        <text
          transform='translate(45.52 32.64)'
          style={{
            fontFamily: 'Calibri,Calibri',
            fontSize: '25.61px',
            fill: '#fff',
          }}
        >
          <tspan x={0} y={0}>
            {'$'}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
export default SvgTransactions;
