import React, { ChangeEvent, useEffect, useState } from 'react';

import { InputField } from './input-field';
import { Search } from '../icons';
import { StyledSearchFieldWrapper } from './form.styles';
import { useDebounce } from '../../hooks/use-debounce';

interface SearchFieldProps extends React.ComponentPropsWithRef<'input'> {
  placeholder: string;
  onSearch: (searchValue: string) => void;
}

export const SearchField = ({ onSearch, placeholder }: SearchFieldProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce<string>(searchValue, 700);

  useEffect(() => {
    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <StyledSearchFieldWrapper>
      <Search width={30} height={30} />

      <InputField
        labelHidden
        id={'search'}
        type={'text'}
        name={'search'}
        isValid={true}
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
      />
    </StyledSearchFieldWrapper>
  );
};
