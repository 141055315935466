import * as React from 'react';
import { SVGProps } from 'react';
const SvgKeysGroups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 53.57 56.63'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.keys-groups_svg__d{fill:#fff}'}</style>
    </defs>
    <g id='keys-groups_svg__b'>
      <g id='keys-groups_svg__c'>
        <path
          className='keys-groups_svg__d'
          d='M37.56 16.68c0-6.15-4.99-11.14-11.14-11.14-2.12 0-4.1.59-5.78 1.62.55.89.96 1.87 1.21 2.92.34-.1.7-.16 1.07-.16a3.74 3.74 0 0 1 3.74 3.74c0 .77-.23 1.48-.63 2.07 2.8 1.97 4.63 5.22 4.63 8.91 0 .85-.1 1.67-.28 2.46 4.2-1.6 7.19-5.66 7.19-10.42Z'
        />
        <path
          className='keys-groups_svg__d'
          d='M26.03 15.72A3.719 3.719 0 0 1 21 16.85a9.838 9.838 0 0 1-4.72 4.42c1.75 3.86 5.64 6.55 10.15 6.55 1.39 0 2.73-.26 3.96-.73.18-.79.28-1.62.28-2.46 0-3.68-1.83-6.94-4.63-8.91ZM19.18 13.65c0-1.69 1.13-3.12 2.67-3.58a9.904 9.904 0 0 0-1.21-2.92 11.181 11.181 0 0 0-5.17 7.48c1.15-.5 2.4-.8 3.71-.87v-.11Z'
        />
        <path
          className='keys-groups_svg__d'
          d='M16.27 21.27c2.03-.9 3.7-2.46 4.72-4.42a3.735 3.735 0 0 1-1.81-3.09c-1.31.07-2.57.37-3.71.87-.12.67-.19 1.35-.19 2.05 0 1.64.36 3.19.99 4.6ZM35.16 21l-4.69 1.62a10.93 10.93 0 0 1-.16 4.77l2.04.43-1.06 1.62 1.58 1.7 3.93.06-.7 2.99 1.76 1.76 3.11-.23-.59 2.4 2.11 2.11 2.23-.88 1.52.88-.76 1.7 8.09.29v-2.87L35.15 20.99Z'
        />
        <path
          className='keys-groups_svg__d'
          d='m30.47 22.62-3.29 1.14 1.47 3.28 1.66.35a10.93 10.93 0 0 0 .16-4.77ZM28.39 31.28a10.848 10.848 0 0 1-7.83 4.2l.47 1.73 3.45 1.87-2.01 2.32.74 2.37L26.07 45l-1.63 1.86.89 2.85 2.38.25.94 1.49-1.46 1.15 7.04 4.01 1.33-2.55-7.18-22.8ZM12.95 16.15c.14-.67.33-1.32.57-1.94-.52.61-.98 1.29-1.37 2.03-.2.39-.38.78-.53 1.18.41-.46.85-.89 1.33-1.27Z'
        />
        <path
          className='keys-groups_svg__d'
          d='m28.39 31.28-.19-.6h.02l-.6-.36c-.91.22-1.86.34-2.84.34-6.67 0-12.08-5.41-12.08-12.08 0-.83.09-1.65.25-2.43-.48.39-.92.81-1.33 1.27-.42 1.1-.66 2.23-.72 3.35a11.155 11.155 0 0 0 9.23 11.62l1.98 1.64-1.69.95.14.51c3.19-.23 5.99-1.82 7.83-4.2Z'
        />
        <path
          className='keys-groups_svg__d'
          d='M2.52 12.32c0-5.41 4.39-9.8 9.8-9.8a9.786 9.786 0 0 1 9.65 11.44c.85.17 1.66.45 2.42.8.16-.79.25-1.61.25-2.45C24.63 5.51 19.12 0 12.32 0S0 5.51 0 12.32c0 5.62 3.76 10.35 8.9 11.83.04-.87.18-1.71.41-2.51a9.786 9.786 0 0 1-6.78-9.32Z'
        />
      </g>
    </g>
  </svg>
);
export default SvgKeysGroups;
