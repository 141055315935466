import React from 'react';
import { StyledLabel, StyledInput, StyledFormFieldWrapper } from './form.styles';

export type LabelPosition = 'vertical' | 'horizontal';

interface InputFieldProps extends React.ComponentPropsWithRef<'input'> {
  label?: any;
  isValid: boolean;
  labelPosition?: LabelPosition;
  labelHidden?: boolean;
}

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    { label, id, type, name, isValid, labelHidden = false, labelPosition = 'vertical', ...props },
    ref,
  ) => {
    return (
      <StyledFormFieldWrapper labelPosition={labelPosition}>
        {label && (
          <StyledLabel htmlFor={id} isValid={isValid} labelPosition={labelPosition}>
            {label}
          </StyledLabel>
        )}
        <StyledInput id={id} type={type} name={name} isValid={isValid} ref={ref} {...props} />
      </StyledFormFieldWrapper>
    );
  },
);
