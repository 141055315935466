import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setAuthData, signOut as logOut } from './auth-slice';
import { AuthResponse } from './auth.models';

export const useAuth = () => {
  const { user } = useSelector(({ auth }: any) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setAuthStateAndRedirectHome = (authResponse: AuthResponse): void => {
    dispatch(setAuthData(authResponse));
    navigate('/');
  };

  const signOut = (): void => {
    dispatch(logOut());
    navigate('/sign-in');
  };

  const isAuthenticated = (): boolean => !!user;

  const getUserRoles = (): string[] => ['ADMIN', 'USER'];

  return {
    setAuthStateAndRedirectHome,
    signOut,
    isAuthenticated,
    getUserRoles,
    loggedUser: user,
  };
};
