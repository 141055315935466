import styled from 'styled-components';

export const StyledModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  min-width: 600px;
  margin: 0 auto;
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
`;
