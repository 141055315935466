import * as React from 'react';
import { SVGProps } from 'react';
const SvgBubbles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 165.12 83.49'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>
        {'.bubbles_svg__d,.bubbles_svg__e{fill:#84cff1;opacity:.64}.bubbles_svg__e{fill:#13aee2}'}
      </style>
    </defs>
    <g id='bubbles_svg__b'>
      <g id='bubbles_svg__c'>
        <circle className='bubbles_svg__d' cx={86.59} cy={70.94} r={12.54} />
        <circle className='bubbles_svg__d' cx={98.71} cy={57} r={5.05} />
        <circle className='bubbles_svg__d' cx={64.19} cy={45.87} r={5.05} />
        <circle className='bubbles_svg__d' cx={56} cy={42.2} r={2.32} />
        <circle className='bubbles_svg__d' cx={105.14} cy={41.61} r={3.65} />
        <circle className='bubbles_svg__d' cx={90.05} cy={53.13} r={2.76} />
        <circle className='bubbles_svg__d' cx={80.27} cy={34.27} r={2.76} />
        <circle
          className='bubbles_svg__d'
          cx={55.46}
          cy={20.81}
          r={6.38}
          transform='rotate(-67.5 55.464 20.813)'
        />
        <circle className='bubbles_svg__d' cx={123.02} cy={62.7} r={10.93} />
        <circle className='bubbles_svg__d' cx={49.08} cy={58.4} r={8.78} />
        <circle className='bubbles_svg__d' cx={11.49} cy={49.62} r={11.49} />
        <circle className='bubbles_svg__d' cx={32.88} cy={14.85} r={5.54} />
        <circle className='bubbles_svg__d' cx={90.62} cy={10} r={8.78} />
        <circle className='bubbles_svg__e' cx={99.14} cy={42.42} r={4.65} />
        <circle className='bubbles_svg__e' cx={95.88} cy={36.34} r={4.08} />
        <circle className='bubbles_svg__e' cx={83.71} cy={48.19} r={5.36} />
        <circle className='bubbles_svg__e' cx={72.5} cy={38.44} r={7.97} />
        <circle className='bubbles_svg__e' cx={42.96} cy={54.31} r={3.9} />
        <circle className='bubbles_svg__e' cx={47.65} cy={30.63} r={3.9} />
        <circle className='bubbles_svg__e' cx={56} cy={21.99} r={2.69} />
        <circle className='bubbles_svg__e' cx={14.89} cy={28.49} r={3.9} />
        <circle className='bubbles_svg__e' cx={129.01} cy={43.1} r={6.76} />
        <circle className='bubbles_svg__e' cx={101.9} cy={59.71} r={2.43} />
        <circle className='bubbles_svg__e' cx={116.39} cy={51.77} r={3.58} />
        <circle className='bubbles_svg__e' cx={159.3} cy={17.92} r={5.83} />
        <circle className='bubbles_svg__e' cx={146.38} cy={31.97} r={2.91} />
        <circle className='bubbles_svg__e' cx={142.39} cy={66.95} r={3.99} />
        <circle className='bubbles_svg__e' cx={118.64} cy={33.58} r={4.38} />
        <circle className='bubbles_svg__e' cx={126.76} cy={18.56} r={3.74} />
        <circle className='bubbles_svg__e' cx={139.5} cy={5.46} r={5.46} />
        <circle className='bubbles_svg__e' cx={95.88} cy={17.52} r={5.26} />
        <circle
          className='bubbles_svg__e'
          cx={78.98}
          cy={11.78}
          r={6.62}
          transform='rotate(-45 78.986 11.773)'
        />
        <circle className='bubbles_svg__e' cx={33.78} cy={34.88} r={7.97} />
      </g>
    </g>
  </svg>
);
export default SvgBubbles;
