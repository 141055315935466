import * as React from 'react';
import { SVGProps } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 27.96 36.93'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.search_svg__e{fill:#25353f;stroke:#354b60;stroke-miterlimit:10}'}</style>
    </defs>
    <g id='search_svg__b'>
      <path className='search_svg__e' d='m17.1 20.98 10.45 15.68' id='search_svg__c' />
      <circle className='search_svg__e' cx={11.51} cy={11.51} r={11.01} id='search_svg__d' />
    </g>
  </svg>
);
export default SvgSearch;
