import { StyledPageSizeList, StyledPageSizeListItem } from './paginator.styles';

export const ChoosePageSize = ({ isOpened, onChange }: any) => {
  if (!isOpened) return null;

  return (
    <StyledPageSizeList>
      <StyledPageSizeListItem onClick={() => onChange(10)}>10</StyledPageSizeListItem>
      <StyledPageSizeListItem onClick={() => onChange(25)}>25</StyledPageSizeListItem>
      <StyledPageSizeListItem onClick={() => onChange(50)}>50</StyledPageSizeListItem>
      <StyledPageSizeListItem onClick={() => onChange(100)}>100</StyledPageSizeListItem>
    </StyledPageSizeList>
  );
};
