import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledMain = styled.main`
  min-width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.blueGrayLight};
  position: relative;
`;

export const StyledLogoLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 20px;
  display: block;
  margin-left: 20px;
  cursor: pointer;
`;

export const StyledNavigation = styled.nav`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const StyledAuthFormWrapper = styled.div`
  position: relative;
  max-width: 600px;
  margin-inline: auto;
  padding: 24px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #9196a3, transparent);
`;

export const StyledBubblesIconWrapper = styled.div`
  position: absolute;
  bottom: -145px;
  right: -40px;
  font-size: 200px;
`;

export const StyledFooter = styled.footer`
  min-height: 20vh;
  background-color: ${({ theme }) => theme.colors.blueGrayLight};
`;
