import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSize } from 'shared/components';
import { useDebounce } from 'shared/hooks/use-debounce';
import { StyledInputPage, StyledPaginatorWrapper, StyledPerPageTitle } from './paginator.styles';
import { ChoosePageSize } from './choose-page-size';

interface PaginatorProps {
  currentPage: number;
  pageSize: number;
  total?: number | undefined;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const Paginator = ({
  currentPage,
  pageSize,
  total,
  onPageChange,
  onPageSizeChange,
}: any) => {
  const [lastPage, setLastPage] = useState(1);
  const { t } = useTranslation(['utils']);
  const [page, setPage] = useState<string | number>(currentPage);
  const [choosePageSizeOpened, setChoosePageSizeOpened] = useState<boolean>(false);
  const [debouncedPageValue] = useDebounce(page, 500);
  const [size, setSize] = useState<string | number>(pageSize);

  useEffect(() => {
    if (!total) return;
    const lastPage = Math.ceil(total / pageSize);
    setLastPage(lastPage);
  }, [total, pageSize]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (page > lastPage) setPage(lastPage);
  }, [lastPage]);

  useEffect(() => {
    if (Number(page) > 0 && Number(page) <= lastPage) onPageChange(Number(page));
  }, [debouncedPageValue]);

  useEffect(() => {
    if (typeof size === 'number') onPageSizeChange(size as number);
  }, [size]);

  const filterValue = (val: string) =>
    val.replaceAll(',', '').replaceAll('.', '').replaceAll('-', '');

  const handleInputPage = (inputPage: string) => {
    const filteredPageValue = filterValue(inputPage);
    setPage(filteredPageValue);
    const pageValue = Number(filteredPageValue);

    if (pageValue > lastPage) {
      setPage(lastPage);
    }

    if (pageValue < 0) {
      setPage(1);
    }

    if (pageValue > 0 && pageValue <= lastPage) {
      setPage(pageValue);
    }
  };

  const handleChangePageSize = (pageSize: number) => {
    setSize(pageSize);
    setChoosePageSizeOpened(false);
  };

  return (
    <StyledPaginatorWrapper>
      <Button
        onClick={() => setPage(1)}
        variant={'primary'}
        type={'button'}
        size={ButtonSize.SMALL}
        isUpperCase={false}
        disabled={currentPage === 1}
      >
        {/*{'<<'}*/}
        {t('first-page')}
      </Button>
      <Button
        onClick={() => setPage(currentPage - 1)}
        variant={'primary'}
        type={'button'}
        size={ButtonSize.SMALL}
        isUpperCase={false}
        disabled={currentPage === 1}
      >
        {/*{'<'}*/}
        {t('prev-page')}
      </Button>
      <StyledInputPage
        onChange={(e) => handleInputPage(e.target.value)}
        value={page}
        type={'number'}
        min={'0'}
        step={'1'}
      />
      <span>/</span>
      <span>{lastPage}</span>
      <Button
        onClick={() => setPage(currentPage + 1)}
        variant={'primary'}
        type={'button'}
        size={ButtonSize.SMALL}
        isUpperCase={false}
        disabled={currentPage === lastPage}
      >
        {/*{'>'}*/}
        {t('next-page')}
      </Button>
      <Button
        onClick={() => setPage(lastPage)}
        variant={'primary'}
        type={'button'}
        size={ButtonSize.SMALL}
        isUpperCase={false}
        disabled={currentPage === lastPage}
      >
        {/*{'>>'}*/}
        {t('last-page')}
      </Button>
      <StyledPerPageTitle>{t('total-pages')}</StyledPerPageTitle>
      <Button
        onClick={() => setChoosePageSizeOpened((prev) => !prev)}
        variant={'primary'}
        type={'button'}
        size={ButtonSize.SMALL}
        isUpperCase={false}
      >
        {pageSize}
      </Button>
      <div style={{ position: 'relative' }}>
        <ChoosePageSize isOpened={choosePageSizeOpened} onChange={handleChangePageSize} />
      </div>
    </StyledPaginatorWrapper>
  );
};
