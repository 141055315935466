import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SignInForm, SignUpForm, ResetPasswordForm } from 'features/auth';
import { RequireAuthenticatedUser, RequireUnauthenticatedUser } from 'guards';
import { AuthLayout, HomeLayout, WithNavigationBoxLinksLayout } from 'shared/components';

const Home = React.lazy(() => import('./home'));
const Accounts = React.lazy(() => import('./accounts'));
const Platforms = React.lazy(() => import('./platforms'));
const KeyMap = React.lazy(() => import('./key-map'));
const KeyGroups = React.lazy(() => import('./key-groups'));
const Transactions = React.lazy(() => import('./transactions'));
const Statistics = React.lazy(() => import('./statistics'));
const Subscription = React.lazy(() => import('./subscription'));

export const Root = () => {
  return (
    <Routes>
      <Route element={<RequireUnauthenticatedUser />}>
        <Route element={<AuthLayout />}>
          <Route path='/sign-in' element={<SignInForm />} />
          <Route path='/sign-up' element={<SignUpForm />} />
          <Route path='/reset-password' element={<ResetPasswordForm />} />
        </Route>
      </Route>

      <Route element={<RequireAuthenticatedUser />}>
        <Route element={<HomeLayout />}>
          <Route path='/' element={<Home />} />
        </Route>

        <Route element={<WithNavigationBoxLinksLayout />}>
          <Route path='/accounts' element={<Accounts />} />
          <Route path='/platforms' element={<Platforms />} />
          <Route path='/key-map' element={<KeyMap />} />
          <Route path='/key-groups' element={<KeyGroups />} />
          <Route path='/transactions' element={<Transactions />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/subscription' element={<Subscription />} />
        </Route>
      </Route>
    </Routes>
  );
};
