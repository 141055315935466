import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppLogoLink } from './app-logo-link';
import { NavigationBox, NavigationBoxColor } from '../navigation-box/navigation-box';
import { StyledMain, StyledNavigation } from './layouts.styles';
import {
  Accounts,
  KeysGroups,
  KeysMaps,
  Platforms,
  SignOut,
  Statistics,
  Transactions,
} from 'shared/components/icons';
import { Footer } from './footer';
import { useAuth } from 'features/auth';

export const WithNavigationBoxLinksLayout = () => {
  const { t } = useTranslation('home');
  const { signOut } = useAuth();

  return (
    <StyledMain>
      <StyledNavigation>
        <AppLogoLink width={150} height={130} />

        <NavigationBox
          path='accounts'
          title={t('accounts.title')}
          Icon={Accounts}
          color={NavigationBoxColor.Accounts}
          isSquare
        />
        <NavigationBox
          path='platforms'
          title={t('platforms.title')}
          Icon={Platforms}
          color={NavigationBoxColor.Platforms}
          isSquare
        />
        <NavigationBox
          path='key-map'
          title={t('key-maps.title')}
          Icon={KeysMaps}
          color={NavigationBoxColor.KeysMaps}
          isSquare
        />
        <NavigationBox
          path='key-groups'
          title={t('key-groups.title')}
          Icon={KeysGroups}
          color={NavigationBoxColor.KeysGroups}
          isSquare
        />
        <NavigationBox
          path='transactions'
          title={t('transactions.title')}
          Icon={Transactions}
          color={NavigationBoxColor.Transactions}
          isSquare
        />
        {/*<NavigationBox*/}
        {/*  path='statistics'*/}
        {/*  title={t('statistics.title')}*/}
        {/*  Icon={Statistics}*/}
        {/*  color={NavigationBoxColor.Statistics}*/}
        {/*  isSquare*/}
        {/*/>*/}
        <NavigationBox
          path='subscription'
          title={t('subscriptions.title')}
          Icon={Statistics}
          color={NavigationBoxColor.Statistics}
          isSquare
        />
        <NavigationBox
          path='sign-in'
          title={t('sign-out')}
          Icon={SignOut}
          color={NavigationBoxColor.SignOut}
          isSquare
          onClick={signOut}
        />
      </StyledNavigation>
      <Outlet />
      <Footer />
    </StyledMain>
  );
};
