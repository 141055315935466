import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface NavigationBoxProps {
  $isSquare: boolean;
}

export const StyledHeader = styled.div<NavigationBoxProps>`
  width: 100%;
  height: ${({ $isSquare }) => ($isSquare ? '70%' : '60%')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
`;

export const StyledLink = styled(Link)<NavigationBoxProps>`
  position: relative;
  display: block;
  text-decoration: none;
  max-width: 400px;
  width: ${({ $isSquare }) => ($isSquare ? '130px' : 'unset')};
  height: ${({ $isSquare }) => ($isSquare ? '130px' : '300px')};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: ${({ $isSquare }) => ($isSquare ? -1 : 1)};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    ${StyledHeader} {
      scale: 1.1;
    }

    &:after {
      filter: brightness(150%);
      top: 0;
    }
  }
`;

export const StyledDescription = styled.div<NavigationBoxProps>`
  padding: ${({ $isSquare }) => ($isSquare ? '0 4px' : '16px 12px')};
`;

export const StyledTitle = styled.span<NavigationBoxProps>`
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: ${({ theme, $isSquare }) => ($isSquare ? theme.fontSizes.s : theme.fontSizes.xxl)};
  margin: 0;
`;

export const StyledSubtitle = styled.span<NavigationBoxProps>`
  display: ${({ $isSquare }) => ($isSquare ? 'none' : 'block')};
  font-size: ${({ theme }) => theme.fontSizes.m};
  margin-top: 1rem;
`;
