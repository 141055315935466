import { StyledLogoLink } from './layouts.styles';
import { Logo } from 'shared/components/icons';

export const AppLogoLink = ({ width, height }: { width: number; height: number }) => {
  return (
    <StyledLogoLink to='/'>
      <Logo fill='#679fbd' width={width} height={height} />
    </StyledLogoLink>
  );
};
