import styled from 'styled-components';

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const StyledTh = styled.th`
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  border: solid 1px ${({ theme }) => theme.colors.blueGrayLight};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.m};
  border-right: none;
  font-weight: normal;
  text-align: left;
  padding: 5px;

  &:last-child {
    border-right: solid 1px ${({ theme }) => theme.colors.blueGrayLight};
  }
`;

export const StyledTd = styled.td`
  border-bottom: solid 1px ${({ theme }) => theme.colors.blueGrayLight};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.m};
  background-color: transparent;
  font-weight: normal;
  padding: 10px;
`;

export const StyledCell = styled.span<{ isEnabled: boolean }>`
  color: ${({ theme, isEnabled }) => (isEnabled ? theme.colors.white : theme.colors.disabled)};
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledInfo = styled.div`
  opacity: 0;
  transition: 0.3s ease;
  scale: 1.5;
  width: 20px;
`;

export const StyledExpandedCell = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${StyledInfo} {
    opacity: 1;
  }
`;

export const StyledExpandedCellDetails = styled.div`
  margin: 10px 0 0 15px;
`;
