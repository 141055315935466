import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthData, signOut } from '../../features/auth/auth-slice';
import { ApiTagsValues } from './api-tags';

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    headers.set('Content-Type', 'application/json');
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRefreshToken = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const token = api.getState().auth.refreshToken;
    const refreshRequestArgs = {
      url: 'login/refresh',
      method: 'POST',
      body: { token },
    };

    const refreshResult = await baseQuery(refreshRequestArgs, api, extraOptions);

    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setAuthData(refreshResult.data));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(signOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithRefreshToken,
  endpoints: () => ({}),
  tagTypes: [...ApiTagsValues],
});
