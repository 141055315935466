import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChooseLanguageWrapper } from './choose-language.styles';
import { SelectField } from '../form/select-field';

export const ChooseLanguage = () => {
  const items = [
    { value: 'pl', text: 'Polski' },
    { value: 'en', text: 'English' },
  ];
  const [defaultLanguage] = useState<any>(
    items.find(({ value }) => value === localStorage.getItem('i18nextLng')),
  );
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  return (
    <StyledChooseLanguageWrapper>
      <SelectField
        onChange={handleChangeLanguage}
        items={items}
        initialValue={defaultLanguage}
        canResetValue={false}
      />
    </StyledChooseLanguageWrapper>
  );
};
