import { Outlet } from 'react-router-dom';

import { StyledMain } from './layouts.styles';
import { AppLogoLink } from './app-logo-link';

export const HomeLayout = () => {
  return (
    <StyledMain>
      <AppLogoLink width={250} height={180} />
      <Outlet />
    </StyledMain>
  );
};
