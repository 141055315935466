import { useSelect } from 'downshift';
import {
  SelectWrapper,
  StyledChooseNoneIcon,
  StyledFormFieldWrapper,
  StyledLabel,
  StyledOption,
  StyledOptionsList,
  StyledOptionTag,
  StyledOptionText,
  StyledSelect,
} from './form.styles';
import { PropsWithChildren, useEffect } from 'react';
import { Button, ButtonSize } from '../button/button';

interface SelectFieldProps<T> {
  items: any;
  onChange: (e: any) => void;
  loadOptions?: () => void;
  initialValue?: any;
  label?: any;
  placeholder?: any;
  labelPosition?: 'vertical' | 'horizontal';
  style?: any;
  totalElements?: number;
  onReset?: any;
  shouldResetValue?: boolean;
  canResetValue?: boolean;
}

export const SelectField = <T,>({
  items = [],
  onChange,
  loadOptions,
  initialValue,
  label,
  labelPosition = 'horizontal',
  placeholder,
  totalElements,
  children,
  canResetValue = true,
  shouldResetValue,
}: PropsWithChildren<SelectFieldProps<T>>) => {
  const itemToString = (item: any) => {
    onChange(item.value);
    return item ? item.text : '';
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    reset,
  } = useSelect({
    items,
    itemToString,
    initialSelectedItem: initialValue,
  });

  useEffect(() => {
    if (!canResetValue) return;
    handleReset();
  }, [shouldResetValue]);

  const handleLoadOptions = (e: any) => {
    if (!loadOptions) return;
    loadOptions();
  };

  const handleReset = () => {
    itemToString({ text: '', value: '' });
    reset();
  };

  return (
    <StyledFormFieldWrapper labelPosition={labelPosition}>
      {label && label !== '' && (
        <StyledLabel labelPosition={labelPosition} isValid={true}>
          {label}

          <StyledChooseNoneIcon>
            {selectedItem && canResetValue && <span onClick={handleReset}>&#10005;</span>}
          </StyledChooseNoneIcon>
        </StyledLabel>
      )}

      <SelectWrapper>
        <StyledSelect {...getToggleButtonProps()} isValid={true}>
          <span>{selectedItem ? selectedItem.text : placeholder}</span>
          <span>{isOpen ? <>&#8593;</> : <>&#8595;</>}</span>
        </StyledSelect>

        <StyledOptionsList {...getMenuProps()} isOpen={isOpen} isScrollable={items.length > 4}>
          {isOpen && children && <StyledOption selected={false}>{children}</StyledOption>}
          {isOpen && items.length === 0 && (
            <StyledOption selected={false} style={{ minHeight: '100px' }}>
              <span>Brak pozycji...</span>
            </StyledOption>
          )}
          {isOpen &&
            items.map((item: any, index: number) => (
              <StyledOption
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
                selected={highlightedIndex === index}
              >
                <StyledOptionText>{item.text}</StyledOptionText>
                {item.tag && <StyledOptionTag>{item.tag}</StyledOptionTag>}
              </StyledOption>
            ))}
          {isOpen && children && items.length > 0 && items.length !== totalElements && (
            <StyledOption selected={false} onClick={handleLoadOptions}>
              <div style={{ textAlign: 'center' }}>Załaduj więcej</div>
            </StyledOption>
          )}
        </StyledOptionsList>
      </SelectWrapper>
    </StyledFormFieldWrapper>
  );
};
