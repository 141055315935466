import * as React from 'react';
import { SVGProps } from 'react';
const SvgPlatforms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 58.73 52.07'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.platforms_svg__d{fill:#fff}'}</style>
    </defs>
    <g id='platforms_svg__b'>
      <g id='platforms_svg__c'>
        <path className='platforms_svg__d' d='m0 26.52 29.37 12.4v-9.84l-17.61-7.63L0 26.52z' />
        <path
          className='platforms_svg__d'
          d='m58.73 26.52-29.36 12.4v-9.84l17.6-7.63 11.76 5.07zM0 39.67l29.37 12.4v-9.83l-17.61-7.63L0 39.67z'
        />
        <path
          className='platforms_svg__d'
          d='m58.73 39.67-29.36 12.4v-9.83l17.6-7.63 11.76 5.06zM0 12.58l29.37 12.41V0L11.76 7.52 0 12.58zM58.73 12.58 29.37 24.99V0l17.6 7.52 11.76 5.06z'
        />
      </g>
    </g>
  </svg>
);
export default SvgPlatforms;
