import { css } from 'styled-components';

export const theme = {
  colors: {
    white: '#ffffff',
    blueGrayLight: '#354b60',
    blueGrayDark: '#25353f',
    darkBlue: '#1f2d39',
    success: '#1f8737',
    danger: '#ce1417',
    primary: 'transparent',
    disabled: 'gray',
    borderGradient: '#9196a3',
    error: '#FF8383',
  },
  fontSizes: {
    xs: '8px',
    s: '12px',
    m: '14px',
    l: '16px',
    xl: '20px',
    xxl: '24px',
  },
  borderedWrapper: css`
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #9196a3, transparent);
  `,
};
