import { configureStore } from '@reduxjs/toolkit';

import { apiSlice } from './api/api-slice';
import { authSlice } from '../features/auth/auth-slice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development',
});
