import styled, { keyframes } from 'styled-components';

const an1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const an3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const an2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

export const LoadingWrapper = styled.div`
  background-color: transparent;
  min-width: 500px;
  max-width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledEllipsis = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      background-color: #13aee2;
      left: 8px;
      animation: ${an1} 0.6s infinite;
    }

    &:nth-child(2) {
      background-color: #84cff1;
      left: 8px;
      animation: ${an2} 0.6s infinite;
    }

    &:nth-child(3) {
      background-color: #13aee2;
      left: 32px;
      animation: ${an2} 0.6s infinite;
    }

    &:nth-child(4) {
      background-color: #13aee2;
      left: 56px;
      animation: ${an3} 0.6s infinite;
    }
  }
`;

export const StyledFullScreenLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;
