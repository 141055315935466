import { LoadingWrapper, StyledEllipsis } from './loading.styles';

export const Loading = () => {
  return (
    <LoadingWrapper>
      <StyledEllipsis>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledEllipsis>
    </LoadingWrapper>
  );
};
