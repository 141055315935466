import { StyledFullScreenLoading } from './loading.styles';
import { Loading } from './loading';

export const FullScreenLoading = () => {
  return (
    <StyledFullScreenLoading>
      <Loading />
    </StyledFullScreenLoading>
  );
};
