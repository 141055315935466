import * as React from 'react';
import { SVGProps } from 'react';
const SvgEbay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1333.33 534.24'
    shapeRendering='geometricPrecision'
    textRendering='geometricPrecision'
    imageRendering='optimizeQuality'
    fillRule='evenodd'
    clipRule='evenodd'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>
        {
          '.ebay_svg__fil1{fill:#0064d2}.ebay_svg__fil0,.ebay_svg__fil1,.ebay_svg__fil2,.ebay_svg__fil3{fill-rule:nonzero}.ebay_svg__fil3{fill:#86b817}.ebay_svg__fil0{fill:#e53238}.ebay_svg__fil2{fill:#f5af02}'
        }
      </style>
    </defs>
    <g id='ebay_svg__Layer_x0020_1'>
      <g id='ebay_svg___1473343242352'>
        <g id='ebay_svg__eBay_logo_x0020__x0028_1_x0029_.svg'>
          <path
            className='ebay_svg__fil0'
            d='M172.41 116.56C78.37 116.56 0 156.46 0 276.83c0 95.36 52.7 155.41 174.84 155.41 143.77 0 152.99-94.7 152.99-94.7h-69.67s-14.94 51-87.57 51c-59.16 0-101.71-39.98-101.71-95.98h266.23v-35.15c0-55.41-35.18-140.84-162.7-140.84zm-2.43 44.93c56.32 0 94.72 34.5 94.72 86.2H70.43c-.01-54.89 50.1-86.2 99.55-86.2z'
          />
          <path
            className='ebay_svg__fil1'
            d='M335.06 0v371.79c0 21.1-1.51 50.73-1.51 50.73h66.43s2.39-21.28 2.39-40.72c0 0 32.83 51.35 122.09 51.35 94 0 157.84-65.26 157.84-158.76 0-86.97-58.65-156.93-157.69-156.93-92.75 0-121.57 50.08-121.57 50.08V-.01h-67.98zm172.4 163.46c63.83 0 104.42 47.37 104.42 110.94 0 68.17-46.88 112.77-103.96 112.77-68.12 0-104.87-53.19-104.87-112.16 0-54.94 32.98-111.55 104.41-111.55z'
          />
          <path
            className='ebay_svg__fil2'
            d='M847.43 116.56c-141.47 0-150.56 77.47-150.56 89.85h70.42s3.69-45.22 75.28-45.22c46.51 0 82.56 21.29 82.56 62.22v14.57h-82.56c-109.61 0-167.56 32.06-167.56 97.13 0 64.03 53.54 98.87 125.9 98.87 98.61 0 130.36-54.48 130.36-54.48 0 21.67 1.67 43.03 1.67 43.03h62.61s-2.43-26.48-2.43-43.4v-146.4c0-95.98-77.42-116.18-145.7-116.18zm77.7 165.13v19.43c0 25.34-15.63 88.33-107.68 88.33-50.41 0-72.02-25.15-72.02-54.32.01-53.09 72.79-53.43 179.7-53.43z'
          />
          <path
            className='ebay_svg__fil3'
            d='M955.21 128.71h79.22l113.7 227.79 113.43-227.79h71.77l-206.62 405.53h-75.28l59.61-113.04-155.83-292.49z'
          />
        </g>
        <g id='ebay_svg__eBay_logo_x0020__x0028_1_x0029_.svg_0'>
          <path
            className='ebay_svg__fil0'
            d='M172.41 116.56C78.37 116.56 0 156.46 0 276.83c0 95.36 52.7 155.41 174.84 155.41 143.77 0 152.99-94.7 152.99-94.7h-69.67s-14.94 51-87.57 51c-59.16 0-101.71-39.98-101.71-95.98h266.23v-35.15c0-55.41-35.18-140.84-162.7-140.84zm-2.43 44.93c56.32 0 94.72 34.5 94.72 86.2H70.43c-.01-54.89 50.1-86.2 99.55-86.2z'
          />
          <path
            className='ebay_svg__fil1'
            d='M335.06 0v371.79c0 21.1-1.51 50.73-1.51 50.73h66.43s2.39-21.28 2.39-40.72c0 0 32.83 51.35 122.09 51.35 94 0 157.84-65.26 157.84-158.76 0-86.97-58.65-156.93-157.69-156.93-92.75 0-121.57 50.08-121.57 50.08V-.01h-67.98zm172.4 163.46c63.83 0 104.42 47.37 104.42 110.94 0 68.17-46.88 112.77-103.96 112.77-68.12 0-104.87-53.19-104.87-112.16 0-54.94 32.98-111.55 104.41-111.55z'
          />
          <path
            className='ebay_svg__fil2'
            d='M847.43 116.56c-141.47 0-150.56 77.47-150.56 89.85h70.42s3.69-45.22 75.28-45.22c46.51 0 82.56 21.29 82.56 62.22v14.57h-82.56c-109.61 0-167.56 32.06-167.56 97.13 0 64.03 53.54 98.87 125.9 98.87 98.61 0 130.36-54.48 130.36-54.48 0 21.67 1.67 43.03 1.67 43.03h62.61s-2.43-26.48-2.43-43.4v-146.4c0-95.98-77.42-116.18-145.7-116.18zm77.7 165.13v19.43c0 25.34-15.63 88.33-107.68 88.33-50.41 0-72.02-25.15-72.02-54.32.01-53.09 72.79-53.43 179.7-53.43z'
          />
          <path
            className='ebay_svg__fil3'
            d='M955.21 128.71h79.22l113.7 227.79 113.43-227.79h71.77l-206.62 405.53h-75.28l59.61-113.04-155.83-292.49z'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgEbay;
