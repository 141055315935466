export type SetCookie = (name: string, value: string, maxAge: number, secure: boolean) => void;
export type GetCookie = (name: string) => null | string;

export const setCookie: SetCookie = (name, value, maxAge, secure) => {
  document.cookie = `${name}=${value}; max-age=${maxAge}; ${secure ? 'secure' : ''}`;
};

export const getCookie: GetCookie = (name) => {
  if (document.cookie === '') {
    return null;
  }

  const cookies = document.cookie.split(/; */);

  const [cookieValue] = cookies
    .map((cookie) => {
      const [cookieName, cookieValue] = cookie.split('=');
      return cookieName === decodeURIComponent(name) ? decodeURIComponent(cookieValue) : null;
    })
    .filter((cookieValue) => cookieValue !== null);

  return cookieValue;
};
