import React, { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import plPL from 'date-fns/locale/pl';

interface ModalContextType {
  isOpen: boolean;
  component: JSX.Element | undefined;
  openModalWith: (component: JSX.Element) => void;
  closeModal: () => void;
}

export const ModalContext = React.createContext<ModalContextType>({} as ModalContextType);

export const ModalProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [component, setComponent] = React.useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    registerLocale('pl-PL', plPL);
  }, []);

  const openModalWith = (component: JSX.Element) => {
    setComponent(component);
    setIsOpen(true);
  };

  const closeModal = () => {
    setComponent(undefined);
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        component,
        openModalWith,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
