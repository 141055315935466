import { Outlet } from 'react-router-dom';

import { AppLogoLink } from './app-logo-link';
import { Bubbles } from 'shared/components/icons';
import { StyledAuthFormWrapper, StyledBubblesIconWrapper, StyledMain } from './layouts.styles';
import { Footer } from './footer';

export const AuthLayout = () => {
  return (
    <>
      <StyledMain>
        <AppLogoLink width={250} height={180} />
        <div style={{ paddingTop: '80px' }}>
          <StyledAuthFormWrapper>
            <Outlet />
            <StyledBubblesIconWrapper>
              <Bubbles />
            </StyledBubblesIconWrapper>
          </StyledAuthFormWrapper>
        </div>
      </StyledMain>
      <Footer />
    </>
  );
};
