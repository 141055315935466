import React from 'react';

import {
  StyledDescription,
  StyledHeader,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
} from './navigation-box.styles';

export enum NavigationBoxColor {
  Accounts = '#ce5353',
  Platforms = '#60a0ad',
  KeysMaps = '#6a9c79',
  KeysGroups = '#d5b238',
  Transactions = '#e88247',
  Statistics = '#d1569b',
  SignOut = '#1F2D39',
}

interface NavigationBoxProps {
  color: NavigationBoxColor;
  title: string;
  subtitle?: string | null;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isSquare?: boolean;
  path: string;
  onClick?: any;
}

enum IconSize {
  Small = 50,
  Big = 100,
}

export const NavigationBox = ({
  color,
  title,
  subtitle,
  Icon,
  isSquare = false,
  path,
  onClick,
}: NavigationBoxProps) => {
  return (
    <StyledLink to={path} color={color} $isSquare={isSquare} onClick={onClick}>
      <StyledHeader $isSquare={isSquare}>
        <Icon
          width={isSquare ? IconSize.Small : IconSize.Big}
          height={isSquare ? IconSize.Small : IconSize.Big}
        />
      </StyledHeader>
      <StyledDescription $isSquare={isSquare}>
        <StyledTitle $isSquare={isSquare}>{title}</StyledTitle>
        <StyledSubtitle $isSquare={isSquare}>{subtitle}</StyledSubtitle>
      </StyledDescription>
    </StyledLink>
  );
};
