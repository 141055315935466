import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { store } from '../../store';
import { GlobalStyle } from '../../assets/styles/global-style';
import { theme } from '../../assets/styles/theme';
import { ModalProvider } from './modal-provider';

export const AppProviders = ({ children }: any) => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ModalProvider>{children}</ModalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
