import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaypal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='paypal_svg__Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 122.88 29.77'
    style={{}}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>
      {
        '.paypal_svg__st0,.paypal_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#009ee3}.paypal_svg__st1{fill:#113984}'
      }
    </style>
    <path
      className='paypal_svg__st0'
      d='M10.72 7.01h8.4c4.51 0 6.21 2.28 5.94 5.64-.43 5.54-3.78 8.6-8.22 8.6H14.6c-.61 0-1.02.4-1.18 1.5l-.95 6.35c-.06.41-.28.65-.6.68H6.59c-.5 0-.67-.38-.54-1.2L9.26 8.21c.13-.81.57-1.2 1.46-1.2z'
    />
    <path
      className='paypal_svg__st1'
      d='M47.18 6.63c2.83 0 5.45 1.54 5.09 5.37-.43 4.55-2.87 7.07-6.72 7.08h-3.36c-.48 0-.72.39-.84 1.2l-.65 4.13c-.1.62-.42.93-.89.93h-3.13c-.5 0-.67-.32-.56-1.03L38.7 7.74c.13-.82.43-1.12.99-1.12h7.49v.01zm-5.1 8.87h2.55c1.59-.06 2.65-1.16 2.76-3.15.06-1.23-.77-2.11-2.09-2.1l-2.4.01-.82 5.24zm18.69 8.58c.29-.26.58-.39.54-.07l-.1.77c-.05.4.11.61.48.61h2.78c.47 0 .7-.19.81-.91l1.71-10.75c.09-.54-.05-.8-.46-.8h-3.06c-.28 0-.41.15-.48.57l-.11.66c-.06.34-.22.41-.36.06-.52-1.23-1.84-1.78-3.69-1.73-4.29.09-7.17 3.34-7.48 7.51-.24 3.23 2.07 5.76 5.12 5.76 2.21-.01 3.2-.66 4.3-1.68zm-2.33-1.65c-1.84 0-3.13-1.47-2.86-3.28.27-1.8 1.99-3.28 3.84-3.28s3.13 1.47 2.86 3.28c-.27 1.8-1.99 3.28-3.84 3.28zm13.98-9.54H69.6c-.58 0-.82.43-.63.97l3.5 10.24-3.43 4.87c-.29.41-.06.78.34.78h3.17c.04 0 .07.01.11.01.34 0 .65-.18.83-.47l10.76-15.43c.33-.47.18-.97-.37-.97h-3c-.51 0-.72.2-1.01.63l-4.49 6.5-2-6.52c-.12-.4-.41-.61-.96-.61z'
    />
    <path
      className='paypal_svg__st0'
      d='M95.44 6.63c2.83 0 5.45 1.54 5.09 5.37-.43 4.55-2.87 7.07-6.72 7.08h-3.36c-.48 0-.72.39-.84 1.2l-.65 4.13c-.1.62-.42.93-.89.93h-3.13c-.5 0-.67-.32-.56-1.03l2.59-16.57c.13-.81.43-1.12.99-1.12l7.48.01zm-5.09 8.87h2.55c1.59-.06 2.65-1.16 2.76-3.15.06-1.23-.77-2.11-2.09-2.1l-2.4.01-.82 5.24zm18.68 8.58c.29-.26.58-.39.54-.07l-.1.77c-.05.4.11.61.48.61h2.78c.47 0 .7-.19.81-.91l1.71-10.75c.09-.54-.05-.8-.46-.8h-3.05c-.28 0-.41.15-.48.57l-.11.66c-.06.34-.22.41-.36.06-.52-1.23-1.84-1.78-3.68-1.73-4.29.09-7.17 3.34-7.48 7.51-.24 3.23 2.07 5.76 5.12 5.76 2.21 0 3.2-.65 4.31-1.67l-.03-.01zm-2.32-1.65c-1.84 0-3.13-1.47-2.86-3.28.27-1.8 1.99-3.28 3.84-3.28 1.84 0 3.13 1.47 2.86 3.28-.27 1.8-2 3.28-3.84 3.28zm12.81 2.97h-3.23a.38.38 0 0 1-.38-.38v-.05l2.82-17.85c.06-.25.28-.43.53-.43h3.23c.21 0 .38.17.38.38v.05l-2.82 17.85c-.05.25-.27.43-.53.43z'
    />
    <path
      className='paypal_svg__st1'
      d='M5.47 0h8.41c2.37 0 5.18.08 7.05 1.73 1.25 1.11 1.91 2.87 1.76 4.77-.52 6.42-4.35 10.01-9.5 10.01H9.04c-.71 0-1.17.47-1.37 1.73l-1.16 7.37c-.08.48-.28.76-.65.79H.68c-.57 0-.78-.43-.63-1.39L3.78 1.4C3.93.45 4.45 0 5.47 0z'
    />
    <path
      d='m7.79 17.5 1.47-9.29C9.39 7.4 9.83 7 10.72 7h8.4c1.39 0 2.51.22 3.39.62-.84 5.71-4.54 8.89-9.38 8.89H9c-.56 0-.96.28-1.21.99z'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#172c70',
      }}
    />
  </svg>
);
export default SvgPaypal;
