import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledModalFormControls, StyledModalFormWrapper } from './form.styles';
import { Button } from 'shared/components';
import { ModalContext } from 'shared/providers';

interface FormWrapperProps {
  // onSubmit: <T>(body: T) => void;
  title?: string | undefined;
  okBtnText?: string | undefined;
  cancelBtnText?: string | undefined;
  onSubmit: any;
}
export const ModalFormWrapper: React.FC<React.PropsWithChildren<FormWrapperProps>> = ({
  onSubmit,
  title,
  okBtnText,
  cancelBtnText,
  children,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('utils');

  return (
    <StyledModalFormWrapper onSubmit={onSubmit}>
      {title && <h2>{title}</h2>}
      {children}
      <StyledModalFormControls>
        <Button variant='success' type='submit' isUpperCase={true}>
          {okBtnText ? okBtnText : t('ok')}
        </Button>
        <Button variant='danger' type='button' isUpperCase={true} onClick={() => closeModal()}>
          {cancelBtnText ? cancelBtnText : t('cancel')}
        </Button>
      </StyledModalFormControls>
    </StyledModalFormWrapper>
  );
};
