import * as React from 'react';
import { SVGProps } from 'react';
const SvgKeysMaps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 56.04 52.74'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.keys-maps_svg__d{fill:#fff}'}</style>
    </defs>
    <g id='keys-maps_svg__b'>
      <g id='keys-maps_svg__c'>
        <path
          className='keys-maps_svg__d'
          d='M26.52 16.21c-5.58 0-10.11 4.53-10.11 10.11s4.53 10.11 10.11 10.11 10.11-4.53 10.11-10.11-4.53-10.11-10.11-10.11Zm0 17.3c-3.99 0-7.23-3.24-7.23-7.23s3.24-7.23 7.23-7.23 7.23 3.24 7.23 7.23-3.24 7.23-7.23 7.23Z'
        />
        <path
          className='keys-maps_svg__d'
          d='M50.7 25.08a5.34 5.34 0 0 0-4.82 3.05L34.9 25.95l-.52 2.64 11 2.18c.18 2.79 2.49 5.01 5.33 5.01s5.35-2.39 5.35-5.35-2.39-5.35-5.35-5.35Zm0 8.26c-1.62 0-2.93-1.31-2.93-2.93s1.31-2.93 2.93-2.93 2.93 1.31 2.93 2.93-1.31 2.93-2.93 2.93ZM42.8 43.69a5.331 5.331 0 0 0-5.54-1.36l-6.04-9.43-2.26 1.45 6.05 9.44c-1.89 2.06-1.88 5.27.08 7.31 2.05 2.13 5.43 2.2 7.56.15s2.2-5.43.15-7.56Zm-5.96 5.73c-1.12-1.17-1.09-3.02.08-4.15s3.02-1.09 4.15.08 1.09 3.02-.08 4.15-3.02 1.09-4.15-.08ZM33.25 3.5c-.74 2-.2 4.15 1.2 5.58l-5.84 9.55 2.29 1.4 5.85-9.57c2.68.8 5.56-.61 6.54-3.27s-.4-5.85-3.17-6.87-5.85.4-6.87 3.17ZM41 6.35c-.56 1.52-2.24 2.3-3.76 1.74s-2.3-2.24-1.74-3.76 2.24-2.3 3.76-1.74 2.3 2.24 1.74 3.76ZM2.43 10.11c1.78 1.16 4 1.12 5.71.08l10.98 10.74 1.91-1.91L10 8.26c1.38-2.44.64-5.56-1.73-7.11S2.48.24.87 2.71s-.91 5.79 1.56 7.4Zm4.51-6.92a2.936 2.936 0 0 1-3.2 4.92 2.936 2.936 0 0 1 3.2-4.92ZM10.14 46.15a5.34 5.34 0 0 0 .5-5.68l9.49-8.38-1.52-2.21-9.76 8.59c-2.33-1.56-5.49-1.06-7.21 1.2s-1.34 5.7 1.01 7.5 5.7 1.34 7.5-1.01Zm-6.57-5.01c.98-1.29 2.82-1.53 4.11-.55s1.53 2.82.55 4.11-2.82 1.53-4.11.55-1.53-2.82-.55-4.11Z'
        />
      </g>
    </g>
  </svg>
);
export default SvgKeysMaps;
