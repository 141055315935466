import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputField, Button } from 'shared/components';

import { ResetPasswordCredentials } from '../auth.models';
import { StyledAuthForm, StyledAuthLink, StyledResponseMessage } from './auth.styles';
import { useResetPasswordMutation } from '../auth-api-slice';

export const ResetPasswordForm = () => {
  const { t } = useTranslation('auth');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordCredentials>();
  const [resetPassword, { isSuccess, isError }] = useResetPasswordMutation();

  return (
    <>
      {t('reset-password')}
      <StyledAuthForm onSubmit={handleSubmit(resetPassword)}>
        <InputField
          id='email'
          label='E-mail'
          type='text'
          {...register('login', { required: true })}
          isValid={!errors.login}
        />

        <Button variant='success' type='submit' isUpperCase={true} onClick={() => ''}>
          {t('reset-password')}
        </Button>
      </StyledAuthForm>
      {isSuccess && (
        <StyledResponseMessage color={'success'}>{t('restore-success')}</StyledResponseMessage>
      )}
      {isError && (
        <StyledResponseMessage color={'error'}>{t('restore-error')}</StyledResponseMessage>
      )}
      <StyledAuthLink to={'/sign-in'}>{t('sign-in')}</StyledAuthLink>
      <StyledAuthLink to={'/sign-up'}>{t('sign-up')}</StyledAuthLink>
    </>
  );
};
