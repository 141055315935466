import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputField, Button } from 'shared/components';

import { SignUpCredentials } from '../auth.models';
import { StyledAuthForm, StyledAuthLink } from './auth.styles';
import { useSignUpMutation } from '../auth-api-slice';
import { useNavigate } from 'react-router-dom';

export const SignUpForm = () => {
  const { t } = useTranslation('auth');
  const [signUp] = useSignUpMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpCredentials>();

  const handleSignUp = async (credentials: SignUpCredentials) => {
    const { address, email, name, taxId, phone, firstname, password, surname } = credentials;
    const body = {
      accountCreateRequest: {
        name: firstname,
        password,
        phone,
        surname,
        username: email,
      },
      address,
      email,
      name,
      taxId,
    };
    try {
      await signUp(body).unwrap();
      navigate('/sign-in');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {t('sign-up')}
      <StyledAuthForm onSubmit={handleSubmit(handleSignUp)}>
        <InputField
          id='firstname'
          label={t('name')}
          type='text'
          isValid={!errors.firstname}
          {...register('firstname', { required: true })}
        />
        <InputField
          id='surname'
          label={t('surname')}
          type='text'
          isValid={!errors.surname}
          {...register('surname', { required: true })}
        />
        <InputField
          id='company-name'
          label={t('company-name')}
          type='text'
          isValid={!errors.name}
          {...register('name', { required: true })}
        />
        <InputField
          id='address'
          label={t('address')}
          type='text'
          {...register('address', { required: true })}
          isValid={!errors.address}
        />
        <InputField
          id='nip'
          label={t('nip')}
          type='text'
          {...register('taxId', { required: true })}
          isValid={!errors.taxId}
        />
        <InputField
          id='phone'
          label={t('phone')}
          type='text'
          {...register('phone', { required: true })}
          isValid={!errors.phone}
        />
        <InputField
          id='email'
          label={t('email')}
          type='text'
          {...register('email', { required: true })}
          isValid={!errors.email}
        />
        <InputField
          id='password'
          label={t('password')}
          type='password'
          {...register('password', { required: true })}
          isValid={!errors.password}
        />
        <InputField
          id='confirm-password'
          label={t('confirm-password')}
          type='password'
          name='confirmPassword'
          isValid={true}
        />
        <Button variant='success' type='submit' isUpperCase={true} onClick={() => ''}>
          {t('sign-up')}
        </Button>
      </StyledAuthForm>
      <StyledAuthLink to={'/sign-in'}>{t('sign-in')}</StyledAuthLink>
    </>
  );
};
