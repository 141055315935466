import React from 'react';

import { StyledButton } from './button.styles';

export type ButtonVariant = 'primary' | 'success' | 'danger';
export enum ButtonSize {
  SMALL = '4px 8px',
  MEDIUM = '12px 24px',
  LARGE = '18px 36px',
}

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: ButtonVariant;
  isUpperCase?: boolean;
  size?: ButtonSize;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  variant = 'primary',
  isUpperCase = true,
  size = ButtonSize.MEDIUM,
  children,
  ...props
}) => {
  return (
    <StyledButton variant={variant} isUpperCase={isUpperCase} size={size} {...props}>
      {children}
    </StyledButton>
  );
};
