import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSize, InputField } from 'shared/components';

import { useAuth } from '../use-auth';
import { SignInCredentials } from '../auth.models';
import { StyledAuthForm, StyledAuthLink, StyledResponseMessage } from './auth.styles';
import { useSignInMutation } from '../auth-api-slice';
import { useEffect, useState } from 'react';

export const SignInForm = () => {
  const { t } = useTranslation('auth');
  const [hasBadCredentials, setHasBadCredentials] = useState(false);
  const [signIn] = useSignInMutation();
  const { setAuthStateAndRedirectHome } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInCredentials>();

  const handleSignIn = async (credentials: SignInCredentials) => {
    try {
      const authData = await signIn(credentials).unwrap();
      setAuthStateAndRedirectHome(authData);
    } catch (err: any) {
      if (err.status === 401) {
        setHasBadCredentials(true);
      }
    }
  };

  return (
    <>
      {t('sign-in')}
      <StyledAuthForm onSubmit={handleSubmit(handleSignIn)}>
        <InputField
          onFocus={() => setHasBadCredentials(false)}
          id='email'
          label='E-mail'
          type='text'
          isValid={!errors.username}
          {...register('username', { required: true })}
        />
        <InputField
          onFocus={() => setHasBadCredentials(false)}
          id='password'
          label={t('password')}
          type='password'
          isValid={!errors.password}
          {...register('password', { required: true })}
        />
        <Button variant='success' type='submit' isUpperCase={true} size={ButtonSize.MEDIUM}>
          {t('sign-in')}
        </Button>
      </StyledAuthForm>
      {hasBadCredentials && (
        <StyledResponseMessage color={'error'}>{t('invalid-sign-in')}</StyledResponseMessage>
      )}
      <StyledAuthLink to={'/reset-password'}>{t('reset-password')}</StyledAuthLink>
      <StyledAuthLink to={'/sign-up'}>{t('sign-up')}</StyledAuthLink>
    </>
  );
};
