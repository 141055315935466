import { useContext } from 'react';
import ReactModal from 'react-modal';

import { ModalContext } from 'shared/providers';
import { StyledModalContent } from './modal.styles';

export const Modal = () => {
  const { isOpen, closeModal, component } = useContext(ModalContext);
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 10,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          height: 'fit-content',
          border: 'none',
          borderRadius: 'unset',
          background: 'linear-gradient(to right, #9196a3, transparent)',
          outline: 'none',
          padding: '1px',
        },
      }}
    >
      <StyledModalContent>{component}</StyledModalContent>
    </ReactModal>
  );
};
