import styled from 'styled-components';
import { ButtonSize, ButtonVariant } from './button';

export const StyledButton = styled.button<{
  variant: ButtonVariant;
  isUpperCase: boolean;
  size: ButtonSize;
}>`
  padding: ${({ size }) => size};
  background-color: ${({ theme, variant, disabled }) =>
    disabled ? 'gray' : theme.colors[variant]};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: solid 1px ${({ theme }) => theme.colors.blueGrayLight};
  text-transform: ${({ isUpperCase }) => (isUpperCase ? 'uppercase' : '')};
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};

  &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
`;
