import * as React from 'react';
import { SVGProps } from 'react';
const SvgAccounts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 53.51 52.07'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>{'.accounts_svg__d{fill:#fff}'}</style>
    </defs>
    <g id='accounts_svg__b'>
      <g id='accounts_svg__c'>
        <path
          className='accounts_svg__d'
          d='m31.33 29.04-4.58 2.65c2.08-.02 3.69.2 5.17.57 7.29 1.8 14.02 7.35 16.9 14.55.47 1.18-.38 2.47-1.65 2.47H6.32c-1.25 0-2.12-1.26-1.67-2.43a23.75 23.75 0 0 1 9.34-11.47c.95-.61 1.08-1.96.28-2.76-.59-.59-1.52-.7-2.22-.25A27.288 27.288 0 0 0 .03 49.94c-.22 1.1.63 2.13 1.76 2.13h49.93c1.12 0 1.98-1.02 1.76-2.12-2.1-10.52-10.24-18.85-20.66-21.22-.52-.12-1.06 0-1.48.31Z'
        />
        <path
          className='accounts_svg__d'
          d='M26.85 0C18.1 0 11 7.09 11 15.85S18.09 31.7 26.85 31.7 42.7 24.61 42.7 15.85 35.6 0 26.85 0Zm0 28.06c-6.79 0-12.3-5.51-12.3-12.3s5.51-12.29 12.3-12.29 12.3 5.51 12.3 12.3-5.51 12.3-12.3 12.3Z'
        />
      </g>
    </g>
  </svg>
);
export default SvgAccounts;
