import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { getCookie, setCookie } from '../../shared/utils/cookie';

export interface AuthState {
  token: string | null;
  refreshToken: string | null;
  user: { userName: string; userId: string } | null;
}

const initialState: AuthState = {
  token: getCookie('_t'),
  refreshToken: getCookie('_r_t'),
  user: getCookie('_t') ? jwtDecode(getCookie('_t') as string) : null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const { jwt, token } = action.payload;
      state.token = jwt;
      state.refreshToken = token;
      state.user = jwtDecode(jwt);
      setCookie('_t', jwt, 60 * 60, true);
      setCookie('_r_t', token, 60 * 60 * 24 * 31, true);
    },
    signOut: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      setCookie('_t', getCookie('_t') as string, 0, true);
      setCookie('_r_t', getCookie('_r_t') as string, 0, true);
    },
  },
});

export const { setAuthData, signOut } = authSlice.actions;
