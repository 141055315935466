import styled from 'styled-components';
import { LabelPosition } from './input-field';

export const StyledFormFieldWrapper = styled.div<{ labelPosition: LabelPosition }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${({ labelPosition }) => (labelPosition === 'vertical' ? 'center' : 'unset')};
  flex-direction: ${({ labelPosition }) => (labelPosition === 'vertical' ? 'unset' : 'column')};
  gap: 10px;
  padding: 8px 0;
`;

export const StyledLabel = styled.label<{ isValid: boolean; labelPosition: LabelPosition }>`
  flex: 1.4;
  text-align: ${({ labelPosition }) => (labelPosition === 'vertical' ? 'right' : 'unset')};
  font-size: 13px;
  color: ${({ theme, isValid }) => (isValid ? theme.colors.white : theme.colors.error)};
  display: flex;
`;

export const StyledInput = styled.input<{ isValid: boolean }>`
  flex: 4;
  padding: 12px 8px;
  border: solid 1px
    ${({ theme, isValid }) => (isValid ? theme.colors.blueGrayLight : theme.colors.error)};
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
`;

export const StyledSearchFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 60%;
`;

export const StyledModalFormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  min-width: 600px;
  margin: 0 auto;
  padding: 24px 18px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    color: ${({ theme }) => theme.colors.white};
    margin-block: 0;
    margin-bottom: 24px;
  }
`;

export const StyledModalFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 18px;
`;

export const SelectWrapper = styled.div`
  position: relative;
  flex: 4.2;
`;

export const StyledSelect = styled.div<{ isValid?: boolean }>`
  display: flex;
  justify-content: space-between;
  min-width: 150px;
  padding: 12px 8px;
  border: solid 1px
    ${({ theme, isValid }) => (isValid ? theme.colors.blueGrayLight : theme.colors.error)};
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.l};
`;

export const StyledOptionsList = styled.ul<{ isOpen: boolean; isScrollable: boolean }>`
  position: absolute;
  top: 30px;
  width: 100%;
  max-height: 300px;
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'scroll' : 'hidden')};
  padding-inline-start: 0;
  background-color: ${({ theme }) => theme.colors.blueGrayLight};
  font-size: ${({ theme }) => theme.fontSizes.l};
  box-shadow: ${({ isOpen }) => (isOpen ? '1px 1px 2px 1px rgba(0, 0, 0, 0.1)' : 'unset')};
  z-index: 2;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    border: 1px solid ${({ theme }) => theme.colors.blueGrayLight};
  }
`;

export const StyledOption = styled.li<{ selected: any }>`
  list-style: none;
  padding: 12px 8px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.darkBlue : theme.colors.blueGrayDark};
  color: ${({ theme }) => theme.colors.white};
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

export const StyledOptionText = styled.span`
  letter-spacing: 1px;
`;

export const StyledOptionTag = styled.span`
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
`;

export const StyledChooseNoneIcon = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  margin-left: auto;
  cursor: pointer;
  font-size: 11px;
`;
