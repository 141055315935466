import * as React from 'react';
import { SVGProps } from 'react';
const SvgStatistics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 52.88 56.44'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <style>
        {
          '.statistics_svg__d,.statistics_svg__e{stroke-width:2px;fill:none;stroke:#fff;stroke-miterlimit:10}.statistics_svg__e{stroke-width:1.5px}'
        }
      </style>
    </defs>
    <g id='statistics_svg__b'>
      <g id='statistics_svg__c'>
        <path
          className='statistics_svg__d'
          d='M1.77 47.02h4.94v8.42H1.77zM12.73 29.01h4.94v26.43h-4.94zM23.91 37.9h4.94v17.54h-4.94zM35.09 24.63h4.94v30.81h-4.94zM46.27 19.9h4.94v35.54h-4.94z'
        />
        <circle className='statistics_svg__e' cx={3.92} cy={18.91} r={3.17} />
        <circle className='statistics_svg__e' cx={14.97} cy={8.17} r={3.17} />
        <circle className='statistics_svg__e' cx={26.6} cy={19.79} r={3.17} />
        <circle className='statistics_svg__e' cx={48.96} cy={3.92} r={3.17} />
        <path
          className='statistics_svg__e'
          d='m6.12 16.62 6.51-6.32M17.19 10.43l7.28 7.02M29.3 18.14 46.47 5.88'
        />
      </g>
    </g>
  </svg>
);
export default SvgStatistics;
