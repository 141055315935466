import { StyledTable, StyledTd, StyledTh } from './table.styles';
import { useTable } from 'react-table';
import { Loading } from '../loading/loading';

interface TableProps {
  data: any;
  columns: any;
  isLoading: boolean;
}

export const Table = ({ data, columns, isLoading }: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  } as any);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // eslint-disable-next-line react/jsx-key
              <StyledTh {...column.getHeaderProps()}>{column.render('Header')}</StyledTh>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <StyledTd {...cell.getCellProps()}>{cell.render('Cell')}</StyledTd>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
