import { apiSlice } from '../../store/api/api-slice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (body) => ({
        url: 'login',
        method: 'POST',
        body,
      }),
    }),
    signUp: builder.mutation({
      query: (body) => ({
        url: 'company',
        method: 'POST',
        body,
      }),
    }),
    getUser: builder.query({
      query: () => ({
        url: 'getAccount',
        method: 'GET',
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: '/account/password/restore',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { useGetUserQuery, useSignUpMutation, useSignInMutation, useResetPasswordMutation } =
  authApiSlice;
