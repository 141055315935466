import styled from 'styled-components';

export const StyledPaginatorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
  color: white;
`;

export const StyledInputPage = styled.input`
  padding: 5px;
  width: 50px;
  border: solid 1px ${({ theme }) => theme.colors.blueGrayLight};
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  color: ${({ theme }) => theme.colors.white};
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledPerPageTitle = styled.span`
  margin-left: 25px;
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const StyledInputPageSize = styled(StyledInputPage)``;

export const StyledPageSizeList = styled.ul`
  position: absolute;
  top: -75px;
  left: -6px;
  padding-inline-start: 0;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.blueGrayDark};
  list-style: none;
  box-shadow: 2px 1px 2px 1px rgba(32, 32, 75, 0.3);
`;

export const StyledPageSizeListItem = styled.li`
  padding: 6px 8px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blueGrayLight};
  }
`;
