import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledAuthForm = styled.form`
  position: relative;
  width: 400px;
  margin: 48px auto;
  display: flex;
  flex-direction: column;

  Button {
    margin-top: 42px;
    align-self: flex-end;
    cursor: pointer;
  }
`;

export const StyledAuthLink = styled(Link)`
  cursor: pointer;
  padding: 0 10px;
  color: white;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.m};

  &:nth-of-type(2n) {
    border-left: solid 2px white;
  }
`;

export const StyledResponseMessage = styled.h4<{ color: 'success' | 'error' }>`
  color: ${({ color, theme }) => (color === 'error' ? theme.colors.danger : theme.colors.success)};
  font-weight: bold;
  border: solid 1px
    ${({ color, theme }) => (color === 'error' ? theme.colors.danger : theme.colors.success)};
  border-radius: 2px;
  width: fit-content;
  margin-left: 12px;
  padding: 8px;
`;
